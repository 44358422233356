/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-checkbox {
    .mat-checkbox-layout {
      display: flex;
    }
    .mat-checkbox-label {
      flex: 1;
      white-space: normal;
    }
  }
  .mat-radio-button .mat-radio-label-content {
    white-space: normal;
  }

  *::-ms-backdrop,
  label.button-toggle { max-width: 400px; } /* IE11 */

}
