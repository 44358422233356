.popover{
    white-space:nowrap;z-index:500!important;
    animation: fadein .75s;padding:.25rem;
    .popover-header{display:none;}
}
.tooltip{background:#fff!important;color:#000!important;}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
