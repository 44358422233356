﻿
// Add swipe to scroll icons on mobile tables
.table-responsive{
    overflow-x: initial;
    @include media-breakpoint-down(md){
        overflow-x: auto;
        &:before{
            content: "\f0ec \00a0 \f0a6 \00a0 Swipe to scroll left to right";font-family: FontAwesome, Arial;font-size: 0.75rem;padding-left: 5px;color: #a94442;
        }
        td,th{white-space: nowrap;}
    }
}
