// Global branded styling. App specific styling should NOT go in this file
.card { box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
  &.card-sm { font-size: .75rem;
    .card-header { padding: 0.25rem .75rem; }
  }
  .card-header { background: #f0f6fa; background-image: linear-gradient(to top, #fcfeff 0%, #cbdce6 100%);
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 { margin: 0; }
  }
}
.card + .card{margin-top:1rem;}
.no-pad .card-block{
    padding:0;
    .card-block{padding: 1.25rem;}
}
