// Global branded styling. App specific styling should NOT go in this file
.alert{position:relative;
    &.icon:before{font-family: FontAwesome;margin-right:5px;}
    &.alert-danger.icon:before, &.alert-warning.icon:before{content: "\f071";}
    &.alert-success.icon:before{content: "\f164";}
    &.alert-info.icon{padding-left:32px;
        &:before{content: "\f05a";position:absolute;top:10px;left:10px;}
    }

    &.alert-sm{}
    &.alert-form{padding: 0.4rem .75rem;margin-top: .5rem;margin-bottom: 0;}
    .close{margin-left:1rem;}
    &.alert-warning.green{
        color: #274827;
        background-color: #c2d3c4;
        border-color: #c2d3c4;
    }
}
