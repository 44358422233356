#modal {
    &:first-of-type{z-index:1039;}
    .modal-header{border-bottom:2px solid $brand-primary;margin-bottom:1rem;}
    .modal-title { border-bottom: 0; font-weight: 700;text-align:center;text-transform:uppercase;color: $brand-primary;
        .fa {background-color:transparent;}
    }
    h3{font-weight:bold;}
    .modal-footer{border:0;}
    &.modal-full .modal-dialog{max-width: initial;margin-left: 1rem;margin-right: 1rem;}
    &.modal-xl .modal-dialog{max-width: 1100px; }
    
}
.modal-backdrop:first-of-type{z-index:1039;}

body .mat-dialog-container { padding: 0;
  //.close:focus { outline: 0; }
}

@include media-breakpoint-down(lg){
    .mat-dialog-container{margin-top:1rem;margin-bottom:1rem; }
    .cdk-overlay-pane{max-width: initial!important;}
    // .cdk-global-overlay-wrapper{overflow-y: auto;position:relative;}
}

.cdk-overlay-pane {
  max-width: calc(100% - 30px) !important;
}
