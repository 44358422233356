// Global branded styling. App specific styling should NOT go in this file
.nav-tabs {
  &.nav-tabs-sm { font-size: 0.8rem;
    .nav-link { padding: 0.25em .5em; }
  }
}
ngb-tabset {
  &.nav-tabs-sm .nav-link { padding: 0.25rem .5rem; font-size: 0.85rem; }
  & > .tab-content {
    & > .tab-pane { border: 1px solid #ddd; border-top: 0; box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1); background: #fff; }
    & > .card { border-top-left-radius: 0; border-top-right-radius: 0; border-top: 0; }
    &.tab-content-visible { position: relative;
      .tab-pane { display: block; opacity: 0; position: absolute; top: 0px; left: 0px; z-index: -1; }
      .tab-pane.active { opacity: 1; position: initial; position: relative; z-index: 1; }
    }
  }
}

.mat-tab-group.mat-primary {
  &.mat-tab-sm {
    .mat-tab-label { padding: 0 1rem; height: 2rem; font-size: .75rem; }
  }
  &.mat-tab-right { }
  .mat-tab-label { min-width: initial; color: $interact-primary; transition: ease .3s;
    &:hover { opacity: 1; }
  }
  .mat-ink-bar { background-color: $brand-primary; }
}
