// Global branded styling. App specific styling should NOT go in this file
label { cursor: pointer; display: block;
  &.success {
    &.icon:before { content: "\f00c"; color: green; }
    .form-control {
      @extend .is-valid;
    }
  }
  &.warning {
    &.icon:before { content: "\f071"; color: orange; }
    .form-control { border-color: orange; }
  }
  &.danger {
    &.icon:before { content: "\f071"; color: red; }
    .form-control {
      @extend .is-invalid;
    }
  }

  &.icon { position: relative;
    &:before { font-family: 'FontAwesome'; position: absolute; left: 8px; cursor: default; top: 50%; transform: translateY(-50%); z-index: 4; }
    .form-control { padding-left: 25px; }
    &.icon-right:before { left: initial; right: 8px;
      .form-control { padding-right: 25px; }
    }
    &.icon-keyboard {
      &:before { content: "\f11c"; }
      .form-control { padding-left: 30px; }
    }
    &.icon-filter:before { content: "\f0b0"; }

    f .clear { position: absolute; right: 10px; top: 50%; transform: translateY(-50%); z-index: 4; }
  }

  &.search {
    &:before { font-family: 'FontAwesome'; position: absolute; left: 8px; content: "\f002"; cursor: default; }
    .form-control { padding-left: 30px; }
  }
  &.dragNDrop { display: block; height: 200px; padding-top: 30px; background-color: #e8e8e8; outline: 5px dashed #595959; outline-offset: -5px; text-align: center; position: relative;
    &:hover, &.hover {
      // outline-color: $interact-primary;
      // color: $interact-primary;
    }
    input { display: block; opacity: 0; position: absolute; top: 0; bottom: 0; left: 0; right: 0; cursor: pointer; }
  }
}

.form-control{
    &[type='checkbox']{cursor:pointer;}
    &:hover, &:focus, &:active{border-color:$interact-primary ;}
    &.form-control-over{position: relative;z-index: 3;border-top-right-radius:0;border-bottom-right-radius:0;border-color: transparent;}
    &.form-control-under{position: absolute;top: 0;left: 0;z-index: 2;
        &.form-control-sm{height: 1.69rem!important;}
    }
}

// Remove dropdown from datepickers
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button{
    display: none;
}

.input-icon {float: right;margin-right: 8px;margin-top: -21px;position: relative;z-index: 2;}
.datepicker .dropdown-menu {top: 2rem !important;}
.form-check{margin:0;}
.form-check-label{position:relative;top:7px;}
.form-group{margin-bottom:0.5rem;
    label{margin-bottom:0;}
    &.required {
        input:not([disabled]), select:not([disabled]), textarea:not([disabled]) {background-color: #ffffe8;}
    }
}

// Form fields
.mat-form-field { width: 100%;

  .mat-form-field-required-marker{display:none;}

  &.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label { color: $bg-primary; }
  }
  .mat-form-field-outline{border-radius:.3rem;}
  .mat-form-field-underline { z-index: 2; margin-left: 2px; margin-right: 2px; width: calc(100% - 4px); }
  .mat-form-field-infix { padding: .25rem;padding-bottom: .75rem;
    input { position: relative; z-index: 2; }
    span { z-index: 2; }
    .mat-form-field-label { }
   // &:before { position: absolute; left: 0; right: 0; bottom: -1px; top: 2px; z-index: 1; display: block; content: ' '; background-color: #f8fafc; border: 1px solid #e6e9eb; border-bottom: 0; border-top-left-radius: .25rem; border-top-right-radius: .25rem; border-radius: .25rem; }
  }

  .mat-form-field-label-wrapper {top: -1.3rem;}
  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, &.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3rem) scale(0.75);
  }


  &.mat-form-field {
    &:after { content: none; }
    &.mat-form-field.ng-valid {
      .mat-form-field-infix { position: relative;
        // &:after { font-family: 'FontAwesome'; position: absolute; z-index: 2; right: 5px; top: 10px; content: "\f164"; cursor: default; color: green; animation: bounceIn .3s $easeInOutBack; animation-fill-mode: both; }
      }
      .mat-form-field-label { }
      .mat-form-field-underline { color: green; }
    }

    .mat-form-field-flex{padding-top:0px;}
    .mat-form-field-wrapper{padding-bottom: 14px;}

    &.mat-form-field.ng-invalid.ng-touched {
      .mat-form-field-infix { position: relative;
        // &:after { font-family: 'FontAwesome'; position: absolute; z-index: 2; right: 7px; top: 9px; content: "\f071"; cursor: default; color: red; animation: bounceIn .3s $easeInOutBack; animation-fill-mode: both; }
      }
      .mat-form-field-label { }
      .mat-form-field-underline { }
    }
    .mat-placeholder-required.mat-form-field-required-marker{color:red;}
    &.ng-valid .mat-placeholder-required.mat-form-field-required-marker{color:$bg-primary;}

    /** Outline style only */
    &.mat-form-field-appearance-outline{margin: .25rem 0;
      .mat-form-field-wrapper{margin:0;padding:0;}
      .mat-form-field-label-wrapper {margin: 0;top: -1.1rem;}
    }
    @keyframes bounceIn {
      0% { transform: scale(.4); }
      90% { transform: scale(1.3); }
      100% { transform: scale(1); }
    }
    
  }

  /** Select wrapper */
  .fieldtype-select{
    .field-icon{right: 26px;}
    .mat-select-arrow-wrapper{position: relative;top: 5px;}
  }

  &.mat-form-field-invalid.cv-touched .mat-form-field-label {color: rgba(0, 0, 0, 0.6)!important;}
  &.mat-form-field-invalid.cv-touchedd .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {background-color: rgba(0, 0, 0, 0.47);height:1px!important;}
  &.mat-form-field-invalid.cv-touched .mat-select .mat-select-arrow {color: rgba(0, 0, 0, 0.6)!important;}

  &.mat-form-field-invalid.cv-touched .mat-form-field-label {color:!important}
  &.mat-form-field-invalid.cv-touched .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {background-color: #ee0000;}
  &.mat-form-field-invalid.cv-touched .mat-seletc .mat-select-arrow {color: #ee0000;}

  // Outline form field
  &.mat-form-field-appearance-outline{margin:0;
    .mat-form-field-label {top: 1.5em;}
    // Hints
    .mat-form-field-subscript-wrapper{position:initial;margin:0;padding:0;}
    .mat-hint{margin-top: .5rem;}

    // Angular materials does not check if touched to fire error/invalid state. This code fixes that
    &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
    &.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {opacity:1;}
    &.mat-form-field-appearance-outline.mat-focused.ng-touched .mat-form-field-outline,
    &.mat-form-field-appearance-outline.mat-form-field-invalid.ng-touched .mat-form-field-outline {opacity:0;}
    &.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {opacity:0;}
    &.mat-form-field-invalid.mat-form-field-invalid.ng-touched .mat-form-field-outline-thick{ opacity:1; }
    &.mat-form-field.mat-form-field-invalid .mat-form-field-label {color: rgba(0, 0, 0, 0.6);}
    &.mat-form-field.mat-form-field-invalid.ng-touched .mat-form-field-label {color: #ee0000;}
  }
}

// Checkbox styling
.mat-checkbox{
  .mat-checkbox-label{white-space: initial;}
  .mat-checkbox-layout{position:relative;
    .mat-checkbox-inner-container{position:absolute;top:4px;}
    .mat-checkbox-label{padding-left: 24px;}
  }
}

// Radio buttons
.mat-radio-button {width: 100%;
    .mat-radio-label-content{word-break: break-word;white-space: initial;line-height: 1.3rem;text-align: left;padding-top: .5rem;padding-bottom: .5rem;width: 95%;}
    label{margin-bottom:0;width: 100%;}
}
.custom {
  .mat-radio-button {
    .mat-radio-label{position:relative;}
    .mat-radio-container{position:absolute;top: 7px;}
    .mat-radio-label-content{padding-left: 2rem;}
  }
}
.mat-button-toggle-group-wrapper .mat-button-toggle-group .mat-button-toggle.reset{box-shadow: none!important;border: 0!important;}
.mat-button-toggle-group-wrapper .mat-button-toggle-group .mat-button-toggle:hover{transform:initial!important;}

// When a radio is inside a button group
.mat-button-toggle-checked {
  .mat-button-toggle-focus-overlay{display:none;}
  .mat-radio-inner-circle {transform: scale(.5)!important;}
}

.mat-calendar{
  .mat-icon-button:after{opacity:1!important;background-color:transparent;}
}


.mat-chip-list{
  .mat-chip{background-color: #fff;color: rgba(0, 0, 0, 0.87);border-radius: 4px;border: 1px solid #b9b9b9;}
}

span.line-height-2x {
  line-height: 2em;
}

// Remove spinner from type=number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
* One offs. Small nudges and offests to make presentation look clean
*/
.icon-group-4 .icon-group{max-width: 380px;}
.assets-assetType button:first-child p{font-size: 80%;}

.icon-group-custom-class{
  padding-left: 9px;
  padding-right: 9px;
}
