@import '../globals/variables';

// Global branded styling. App specific styling should NOT go in this file
body{
  background-color: $grey;
  background-repeat: no-repeat;
  background-position: 50% 0;
}
.container { max-width: 1280px; }
code{display:block;padding:0.5rem;}
section { padding: 1rem; background: white; padding-bottom: 0.2rem; margin-bottom: 1rem; border: 1px solid #dee0e8;
    &.nopad {padding:0; }
    & > .table { margin: -1rem; margin-bottom: 0; }
}
.embedded header{display:none;}

.banner-overlap {
  .content-main{margin-top:-40px;}
  #site-banner{padding-bottom:40px;}

  @media (min-width: 1024px){
    #site-banner{padding-bottom:50px;}
}
}

.api-hover{position:fixed;bottom:20px;right:20px;z-index:200;
    .alert{box-shadow: 3px 3px 8px rgba(0,0,0,0.5);}
}

.sticky {position: sticky;top: 0;z-index: 150;}
.sticky-error{
    position:sticky;margin:1.5rem;bottom:20px;z-index:5;box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
    code{display:block;margin-top:3px;font-size: 0.75rem;}
}

.no-support{
    @extend .alert-danger;padding:.5rem 0;position:absolute;top:0px;left:0px;right:0px;
}

@include media-breakpoint-up(lg){
    .w-lg-auto{width:auto!important;}
}
.mat-card {
  font-family: inherit;
}



.group-icon {
  border-right-width: 3px;
  border-right-style: solid;
  text-align: right;
  font-size: 3.3rem;
}
  
  .summary-group {
    //.icon{display:none;}
    margin-bottom: 10px;
    &.summary-group-spacer{
      margin-top: 15px;
      .icon{display:block;}
    }
  }
  
  .summary-page {
    $heading-border: 2px solid #ededed;
    margin-bottom: 5px;
    .heading {
      padding: 5px 0 2px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: $heading-border;
      border-bottom: $heading-border;
      font-size: 1.15rem;
      //color: $brand-primary;
      .edit {
        font-size: 0.9rem;
        font-weight: bold;
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .values {
      ul {
        margin-bottom: 0;
        padding-left: 1.2em;
      }
    }
  }

  .apply-new-loan {
    width: fit-content;
    @media (min-width: 993px) {
      margin: -92px 0 0 0;
      float: right;
    }
  
    @media(max-width: 992px) {
      margin: -20px auto -20px auto;
    }
  }

  .summary-personal-mat-panel-title{
    margin-bottom: 0px;
  }

