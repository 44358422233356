// Global branded styling. App specific styling should NOT go in this file

// Buttons
button { cursor: pointer;
  &:focus{ outline: -webkit-focus-ring-color auto 1px;}
}

.btn {
  cursor: pointer;border:0;line-height: 40px;font-family: Roboto, "Helvetica Neue", sans-serif;font-size: 16px;padding-top:0;padding-bottom:0;
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
  @include transition( 0.1s);
  &:focus{ outline: -webkit-focus-ring-color auto 1px;}
  &:hover:not([disabled]) {
    box-shadow: 2px 4px 5px 0 rgba(0,0,0,.16),2px 4px 10px 0 rgba(0,0,0,.12);
    @include transition( 0.1s);
  }
  //transform: translate(-2px, -2px) scale(1.03);
  &.btn-static { transition: none !important; transform: none !important; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12) !important; }
  &.btn-xs { padding: 0.2rem 0.5rem; font-size: 0.6rem; }

  &.btn-submit{
    min-width:200px;
  }

  &.btn-primary{
    color: #fff;
    // background-color: $interact-primary;
    // @include gradient($interact-primary-lt,$interact-primary);
    border: 1px solid #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,.25);
    &:hover:not([disabled]) { background: #fff;
      // color: $interact-primary;
      border: 1px solid $interact-primary; box-shadow: initial; text-shadow: none; }
  }

  &.btn-secondary {
    color: #fff;
    background-color: $brand-primary;
    border: 1px solid #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,.25);
    &:hover:not([disabled]) { background: #fff;color: $brand-primary; border: 1px solid $brand-primary; box-shadow: initial; text-shadow: none; }
  }
  &.btn-outline-secondary {
    color: $brand-primary;
    background-color:transparent;
    border: 1px solid $brand-primary;
    /*text-shadow: 1px 1px 2px rgba(0,0,0,.25);*/
    &:hover:not([disabled]) {
      //background-color: $brand-primary;
      //border: 1px solid #fff;
      color:#fff;
      box-shadow: initial;
      text-shadow: none;
    }
  }
  &.btn-outline-secondary.selected {
    color: #fff;
    background-color: $brand-primary;
    border: 1px solid #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,.25);
  }

  &.btn-outline-primary {
    // color: $interact-primary;
    background: #fff; border: 1px solid $interact-primary; box-shadow: initial;
    &:hover:not([disabled]) {
      color: #fff;
      @include gradient($interact-primary,$interact-primary);
      border: 1px solid $interact-primary;
    }
  }

  &.active, &.enabled { background-image: linear-gradient(to top, #ad0000 0%, #ff4343 100%); color: #fff; border: 1px solid red; }

  &.btn-waiting {
    .fa { display: none; }
    &:before { font-family: FontAwesome; content: "\f110"; animation: fa-spin 2s infinite linear; display: inline-block; position: relative; left: -3px; }
  }
  &.btn-waiting-after {
    .fa { display: none; }
    &:after { font-family: FontAwesome; content: "\f110"; animation: fa-spin 2s infinite linear; display: inline-block; position: relative; left: -3px; }
  }

  &[disabled] { color: dimgray; border-color: #a7a7a7; background: #ededed; }

  @include media-breakpoint-down(md) {
    &.btn-full { display: block; width: 100%; }
  }
}

// Button Groups
.btn-group {
  -webkit-appearance: initial!important;
  & > *:not(:last-child):not(.dropdown-toggle) > .btn { border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: 0; }
  & > *:not(:first-child) > .btn { border-top-left-radius: 0; border-bottom-left-radius: 0; }
}
.input-group .btn, .input-group .btn:hover { box-shadow: none; transform: none; }


// Button toggle
.mat-button-toggle{
  &.mat-button-toggle-checked{
    //background-image: linear-gradient(to bottom, $interact-primary-lt 0%, $interact-primary 100%);
    //background: $brand-primary;
    //box-shadow: 0px 0px 30px rgba(0,0,0,0.5)!important;
    //color: $brand-primary!important;
    background-color:transparent!important;
    //color:#fff;
  }
}

// Mat button toggle group
.mat-button-toggle-group-wrapper{position:relative;
  .mat-button-toggle-group{border:0;overflow:initial!important;
    .mat-button-toggle {
      margin-bottom:1rem;border: solid 1px rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      @include hoverShadow;
      //box-shadow: 0 0 10px rgba(0,0,0,.3)!important;

      &.mat-button-toggle-checked {
        //box-shadow: 5px 5px 17px 1px rgba(0,0,0,0.33) !important;
        //color: $brand-primary!important;
        background-color:transparent!important;
      }
    .mat-button-toggle-button{
      line-height: 5rem;
      &:focus {
        border: none !important;
      }
    }
    }
  }
  &.mat-button-toggle-group-invalid{
    .mat-button-toggle-group-appearance-standard{border-color:red;}
    // .mat-button-toggle + .mat-button-toggle {border-color:red;}
  }
  .required{position: absolute;top:-9px;right:-2px;z-index:2;}
}

// Radio buttons
.mat-button-toggle-appearance-standard.mat-button-toggle{
  .mat-button-toggle-label-content{padding:0;line-height:initial;}
  .mat-radio-label{line-height: 68px;display: block;padding-left: 12px;padding-right: 12px;}
  .mat-radio-container{top: 6px;}
}

// Material buttons
body {
  button.mat-menu-item:focus {outline:initial!important;}
  .mat-menu-item{line-height: 35px;height: 35px;}
  .mat-button, .mat-raised-button, .mat-stroked-button, .mat-icon-button { transition: .3s ease-out; font-weight: normal ; position: relative; overflow: hidden; background-image: linear-gradient(to top, #fff 0%, #fff 100%);
    // color: $interact-primary;
    min-width: initial; line-height: 1.85rem; padding: 0 .55rem;
    &:hover { color: #fff; }
    //&.cdk-focused:hover{ color: $interact-primary!important; }
    &:after { transition: .3s ease-out; content: ' '; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1; opacity: 0;
      //background: $interact-primary-lt;
      //background-image: linear-gradient(to bottom, $interact-primary-lt 0%, $interact-primary 100%);
    }
    &:hover:after { opacity: 1; }
    &.sm{line-height: 1.25rem;font-size: .7rem;}
    //&.cdk-focused .mat-button-wrapper { color: #fff !important; }
    &.cdk-focused:after { display: none; }
    .mat-button-wrapper { z-index: 2; position: relative; }
    &.mat-reverse, &.mat-flat-button {
      //background: $interact-primary-lt;
      //background-image: linear-gradient(to bottom, $interact-primary-lt 0%, $interact-primary 100%);
      .mat-button-wrapper { color: #fff; }
      &:hover {
        background-image: linear-gradient(to bottom, #fff 20%, #e9ecef 100%);
        .mat-button-wrapper {
          //color: $interact-primary;
        }
      }
      &:after { transition: .3s ease-out; content: ' '; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1; opacity: 0; background: #fff; }
      &.mat-stroked-button .mat-button-wrapper{
        //color: $interact-primary;
      }
    }
    &.active, &.enabled { transition: .3s ease-out; background-image: linear-gradient(to top, #ad0000 0%, #ff4343 100%)!important; color: #fff; border: 1px solid red; opacity: 1; }
    &[disabled] { border: 1px solid #a7a7a7 !important; background: #ededed !important;
      .mat-button-wrapper { color: dimgray !important; }
      &:after { display: none; }
    }
    &.mat-waiting {
      .fa { display: none; }
      &:before { font-family: FontAwesome; content: "\f110"; animation: fa-spin 2s infinite linear; display: inline-block; position: relative; left: -3px; }
    }
    &.mat-waiting-after {
      .fa { display: none; }
      &:after { font-family: FontAwesome; content: "\f110"; animation: fa-spin 2s infinite linear; display: inline-block; position: relative; left: -3px; }
    }
    &.mat-stroked-button{background-image: linear-gradient(to bottom, #fff 20%, #e9ecef 100%);}
  }
}

// Icon group
.icon-group {
  margin: auto;
  position: relative;
  //max-width: 500px;
  text-align: center;

  .icon-group-img {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 50px;
  }

  p {
    line-height: 1.3rem;
    white-space: normal;
    font-size: 14px;
  }

  .icon-group-btn {
    @include hoverShadow;
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: initial;
    border: 0;
    word-break: break-word;
    border-radius: .1rem;
    width: 125px;
    min-height: 125px;
    position: relative;
    margin-bottom: 1rem;
    line-height: 1.3rem;

    &.icon-group-btn-invalid {
      border-color: red;
    }

    &.icon-group-btn-lg {
      width: calc(50% - 30px);
      min-width: 220px;
      .icon-group-img {
        width: auto;
        height: 64px;
        font-size: 71px;
        margin-top: 36px;
      }
    }

    &:hover {
      background-color: transparent !important;
      //color: $brand-primary!important;
      /*border: 1px solid $brand-primary!important;*/
    }

    &:focus { /*border-color: rgba(0, 123, 255, 0.25)!important;*/
    }

    &:before {
      content: ' ';
      display: block;
      top: 10px;
      left: 10px;
      position: absolute;
      z-index: 2;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
    }

    &.icon-group-btn-active {
      //box-shadow: 0px 0px 30px rgba(0,0,0,0.5);
      &:after {
        content: ' ';
        z-index: 3;
        display: block;
        top: 13px;
        left: 13px;
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $interact-primary;
      }
    }
  }
}
