// Global branded styling. App specific styling should NOT go in this file
// Cuz :host doesn't support media queries
@media (min-width: 1024px) {
  app-nav{ width: 100%;}
}


.nav-buttons {
  $button-border-color: #dfdfdf;
  margin: 25px -16px -16px;
  border-top: 1px solid $button-border-color;
  button {
    &.mat-flat-button {
      background: #fff !important;
      color: #484f59 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      i {
        vertical-align: inherit;
        transition: transform ease .4s;
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform ease .4s;
      transform: scaleY(0);
      transform-origin: top center;
    }
    &:not([disabled]):hover {
      &.back i {
        transform: translateX(-10px);
      }
      &.next i,
      &.submit i {
        transform: translateX(10px);
      }
      &:after {
        transform: scaleY(1);
      }
    }
    + button {
      border-left: 1px solid $button-border-color;
    }
  }
}
