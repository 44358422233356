@font-face {
    font-family: "cvicons";
    src: url("/assets/fonts/cvicons/cvicons.eot?42bcf114266089021579d969a17a7eab?#iefix") format("embedded-opentype"),
url("/assets/fonts/cvicons/cvicons.woff2?42bcf114266089021579d969a17a7eab") format("woff2"),
url("/assets/fonts/cvicons/cvicons.woff?42bcf114266089021579d969a17a7eab") format("woff"),
url("/assets/fonts/cvicons/cvicons.ttf?42bcf114266089021579d969a17a7eab") format("truetype"),
url("/assets/fonts/cvicons/cvicons.svg?42bcf114266089021579d969a17a7eab#cvicons") format("svg");
}

i[class^="cvi-"]:before, i[class*=" cvi-"]:before {
    font-family: cvicons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cvi-active-duty:before {
    content: "\f101";
}
.cvi-alimony-no:before {
    content: "\f102";
}
.cvi-alimony-yes:before {
    content: "\f103";
}
.cvi-annual:before {
    content: "\f104";
}
.cvi-application-summary:before {
    content: "\f105";
}
.cvi-bank-amp--financial-assets:before {
    content: "\f106";
}
.cvi-better-rate-and-term:before {
    content: "\f107";
}
.cvi-bnl-appraisal:before {
    content: "\f108";
}
.cvi-bnl-esign:before {
    content: "\f109";
}
.cvi-bnl-upload:before {
    content: "\f10a";
}
.cvi-bnl-wetsign:before {
    content: "\f10b";
}
.cvi-civilian:before {
    content: "\f10c";
}
.cvi-coBorrower-no:before {
    content: "\f10d";
}
.cvi-coBorrower-yes:before {
    content: "\f10e";
}
.cvi-credit-unable-to-retrieve:before {
    content: "\f10f";
}
.cvi-creditcheck:before {
    content: "\f110";
}
.cvi-current-address:before {
    content: "\f111";
}
.cvi-debt-consolidation:before {
    content: "\f112";
}
.cvi-dependents:before {
    content: "\f113";
}
.cvi-employment-w-2:before {
    content: "\f114";
}
.cvi-gifts-or-grants:before {
    content: "\f115";
}
.cvi-home-improvement:before {
    content: "\f116";
}
.cvi-home:before {
    content: "\f117";
}
.cvi-hourly:before {
    content: "\f118";
}
.cvi-i-dont-have-any-assets:before {
    content: "\f119";
}
.cvi-i-dont-have-any-income:before {
    content: "\f11a";
}
.cvi-income-businesspartnerships:before {
    content: "\f11b";
}
.cvi-living-rent-free:before {
    content: "\f11c";
}
.cvi-loan-details:before {
    content: "\f11d";
}
.cvi-loan-locked:before {
    content: "\f11e";
}
.cvi-mailing-address:before {
    content: "\f11f";
}
.cvi-manually-enter-your-asset:before {
    content: "\f120";
}
.cvi-manually-enter-your-income:before {
    content: "\f121";
}
.cvi-maritalstatus-married:before {
    content: "\f122";
}
.cvi-maritalstatus-other:before {
    content: "\f123";
}
.cvi-maritalstatus-separated:before {
    content: "\f124";
}
.cvi-maritalstatus-unmarried:before {
    content: "\f125";
}
.cvi-married:before {
    content: "\f126";
}
.cvi-military-pay:before {
    content: "\f127";
}
.cvi-military-reserves2:before {
    content: "\f128";
}
.cvi-military:before {
    content: "\f129";
}
.cvi-monthly:before {
    content: "\f12a";
}
.cvi-national-guard--reserves:before {
    content: "\f12b";
}
.cvi-new-loan-no-lo:before {
    content: "\f12c";
}
.cvi-no-i-havent:before {
    content: "\f12d";
}
.cvi-other-assets:before {
    content: "\f12e";
}
.cvi-other-income:before {
    content: "\f12f";
}
.cvi-other:before {
    content: "\f130";
}
.cvi-own:before {
    content: "\f131";
}
.cvi-pension-retirement:before {
    content: "\f132";
}
.cvi-propertyrights-i-dont-know:before {
    content: "\f133";
}
.cvi-propertyrights-no:before {
    content: "\f134";
}
.cvi-propertyrights-yes:before {
    content: "\f135";
}
.cvi-purchase:before {
    content: "\f136";
}
.cvi-refi-other:before {
    content: "\f137";
}
.cvi-refinance:before {
    content: "\f138";
}
.cvi-rent:before {
    content: "\f139";
}
.cvi-reo-no:before {
    content: "\f13a";
}
.cvi-reo-yes:before {
    content: "\f13b";
}
.cvi-reserves:before {
    content: "\f13c";
}
.cvi-retired--discharged:before {
    content: "\f13d";
}
.cvi-safari-pinned-tab:before {
    content: "\f13e";
}
.cvi-salaried:before {
    content: "\f13f";
}
.cvi-secure-docs:before {
    content: "\f140";
}
.cvi-self-employed:before {
    content: "\f141";
}
.cvi-separated:before {
    content: "\f142";
}
.cvi-social-security:before {
    content: "\f143";
}
.cvi-spouse-add:before {
    content: "\f144";
}
.cvi-spouse-dontAdd:before {
    content: "\f145";
}
.cvi-submit-done:before {
    content: "\f146";
}
.cvi-submit-ready:before {
    content: "\f147";
}
.cvi-surviving-spouse:before {
    content: "\f148";
}
.cvi-take-some-cash-out:before {
    content: "\f149";
}
.cvi-tasks:before {
    content: "\f14a";
}
.cvi-unmarried:before {
    content: "\f14b";
}
.cvi-verify-assets-securely:before {
    content: "\f14c";
}
.cvi-verify-income-securely:before {
    content: "\f14d";
}
.cvi-workingwithagent-no:before {
    content: "\f14e";
}
.cvi-workingwithagent-yes:before {
    content: "\f14f";
}
.cvi-yes-i-signed:before {
    content: "\f150";
}
