// Global branded styling. App specific styling should NOT go in this file
ngb-accordion{
    .card + .card{margin-top:0;border-top: 0;}
    & > .card{border-radius: 0;
      & > .card-header{
          padding:0;font-size: 1.15rem; font-weight: bold;border-bottom:0;background-image: none;background-color: #f7f7f9;
          &:hover{background: #f0f6fa;}
          & > a{
              display:block;padding: 0.75rem 1.25rem;
            color: $interact-primary;
              &:before{font-family: FontAwesome;margin-right:7px;content: "\f0da";@include transition();display:inline-block;}
          }
          &.active > a:before{transform: rotate(90deg);@include transition(.1);}
      }
    }

    // Inline accordion that is smaller
    &.accordion-md{
        & > .card{background: transparent; padding: 0;
        & > .card-header {
            padding: 0; font-size: 1.1rem;font-weight:normal;
            a{display:block;padding: 0.4rem .75rem;text-decoration:none;}
            a:hover{text-decoration:none;}
        }
        & > .card-block{ padding: 0;padding-top: .75rem;}
        }
    }

    // Inline accordion that is smaller
    &.accordion-sm{
        & > .card{background: transparent; padding: 0;
        & > .card-header {
            padding: 0; font-size: 1rem;font-weight:normal;
            a{display:block;padding: 0 0.5rem;text-decoration:none;}
            a:hover{text-decoration:none;}
        }
        & > .card-block{ padding: 0;padding-top: .75rem;}
        }
    }

    // Inline accordion that is smaller
  &.accordion-inline{
      & > .card{background: transparent;border: 0; padding: 0;
          & > .card-header {
              padding: 0; border: 0;background: transparent;font-size: 1rem;font-weight:normal;
              a{display:block;padding: 0 0.5rem;}
          }
      }
      & > .card-block{ padding: 0;padding-top: .75rem;}
  }

  &.accordion-dk{
    .card-header > a{color:white;}
  }
}

.mat-accordion{
  .mat-expansion-panel{
    //box-shadow: initial;
    //border-radius: 0 !important;
    //border-bottom: 2px solid #ccc;
  }

}

.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  &.mat-expanded {
    border-bottom: 2px solid #ccc;
  }
  .mat-expansion-panel-header {
    padding: 0 13px;
    font: inherit;
    background: rgba(0, 0, 0, 0.04);
    border-bottom-width: 2px;
    border-bottom-style: solid;
    .show {display: none;}
    .hide {display: block;}
    //&:not([aria-disabled='true']) {
    //  background: rgba(0, 0, 0, 0.04);
    //}
  }
  .mat-expansion-panel-body {
    padding: 1.2rem 0;
  }
}
