// Global branded styling. App specific styling should NOT go in this file

html, body {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #484F59;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 { font-weight: 400; margin-bottom: .75rem;
  small { font-size: 70%; }
}
h1, .h1 { font-size: 1.4rem; }
h2, .h2 {
  font-size: 1.36rem;
}
h3, .h3 { font-size: 1rem; }
h4, .h4 { font-size: 0.8rem; }
h5, .h5 { font-size: 0.6rem; }
.title { border-bottom: 2px solid $brand-primary; margin-bottom: 0;
  .fa { background-color: #5e9aca; color: #fff; display: inline-block; line-height: 2rem; padding: 0px .5rem; }
}

.section-label{color: $brand-primary;font-size:1rem;font-weight:bold;}
.section-title{font-size:2rem;}
.cvFormBuilder .page-title{font-size: 1.5rem;margin-bottom:30px;}

.title-sub{color:#046492;}

@include media-breakpoint-down(md) {
  h1, .h1 { font-size: 1.75rem; }
  h2, .h2 { font-size: 1.25rem; }
  h3, .h3 { font-size: 1rem; }
}

.legal { font-size: 0.75em; color: #777; }
.strong { font-weight: bold; }
small, .small { font-size: 80%; font-weight: 400; }
.go-back { line-height: 3rem; }

// Colors
.red { color: red; }
.green { color: green; }
.grey { color: #ccc; }

.mat-icon.material-icons {
  &.mat-icon-inline { position: relative; top: 2px; }
  &:focus { outline: none; }
}

.mat-danger.delete-btn {
  font-size: 0.875rem;
  border: rgb(206, 207, 209) solid 0.0625rem;
  font-weight: 500;
  font-family: inherit;
}
